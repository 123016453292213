<template>
  <div class="container">
    <img :src="logo" alt="" class="logo">
    <div class="slogan">
      智客互动拥有多个主管税务机关颁发的《委托代征证书》，与国内多家银行和头部支付公司建立资金监管和支付结算战略合作，保证资金安全。
    </div>
    <div class="tips">
      <p>TO PAY TAXES ACCORDING TO LAW</p>
      <p>依法诚信纳税是中国公民的义务</p>
    </div>

  </div>
</template>
<script>
export default {
  name: 'about',
  data () {
    return {
      logo: require('@/assets/logo.png'),
      text: ''
    }
  },
  methods: {

  }

}
</script>
<style scoped>
  .container{
    background: #fff;
  }
  .logo{
    width: 400px;
    margin: 340px auto 0;
  }
  .slogan{
    margin: 200px auto 280px;
    padding: 0 80px;
    font-size: 34px;
    text-indent: 72px;
    color: #333;
    text-align: justify;
  }

  .tips{
    font-size: 14px;
    color: #cfcfcf;
  }
</style>
